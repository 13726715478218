import styled from "styled-components";
import { COLORS } from "../../../styles/colors";


export const FooterSection = styled.footer`
    background-color: ${COLORS.primary};
    width: 100%;
    padding: 20px 0px;
    display:flex;
`

export const FooterTop = styled.div`
    width: 100%;
    display:flex;
    justify-content: space-between;

    @media(max-width: 1000px) {
        flex-direction: column;
    }
`
export const FooterBox = styled.div`
    display:flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    align-items: center;

    @media(max-width: 1000px) {
        margin: 20px 0px;
        width: 100%;
    }
`
export const FooterText = styled.p`
    color: ${COLORS.white};
    font-size: 13px;
`

export const FooterIcon = styled.img`
    margin-right: 10px;
`

export const FooterMid = styled.div`
    display:flex;
    line-height: 18px;
    align-items: center;
    padding: 45px 0px 35px 0px;
    width: 100%;
`

export const FooterList = styled.ul`
    list-style: none;
    display:flex;
    justify-content: space-between;
    width: 100%;

    @media(max-width: 1000px) {
        flex-direction: column;
        align-items: center
    }

`
export const FooterListItem = styled.li`
    padding-right: 50px;
    border-right: 1px solid ${COLORS.white};

    @media(max-width: 1000px) {
        border-right: none;
        margin: 10px 0px;
        padding-right: 0px;
    }

    &:last-child{
        border-right: none;
        padding-right: 0px;
    }
`
export const FooterListItemLink = styled.a`
    color: ${COLORS.white};
    font-size: 17px;
    text-decoration: none;
    font-weight: bold;
`

export const FooterBottom = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between
`