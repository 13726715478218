import React, { Component, useState, useEffect } from 'react';
import { LineDivisor, ButtonForm } from "../../../styles/general"
import {
    Form, TitleForm, TextNormal, RequiredBox,
    FormRow, FormField, FormLabel, FormInput, FormSmallField, FormSelectInput, FormFullField,
    FormTextAreaInput, FormFullCheckField, FormInputCheck, LabelFor, ButtonLink, ErrorMessage
} from "./styles"
import {COLORS} from "../../../styles/colors"
import InputMask from 'react-input-mask';
import { useForm } from "react-hook-form";
import Calendar from "../../../components/calendar"
import moment from "moment"
import API from "../../../services/api"
import ReCAPTCHA from "react-google-recaptcha";
import { cpf } from 'cpf-cnpj-validator'; 
import 'moment/locale/pt-br';
import ModalAlert from "../../../components/shared/modal"
moment.locale('pt-BR');

export default function FormStep({cadastro, setCadastro, step}){
    const regexCPF = /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/i
    const regexCelular =/^\(?\d{2}\)?[\s-]?[\s9]?\d{4}-?\d{4}$/i
    const regexTelefone =/^(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/i
    const regexEmail =  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const regexNome = /^[a-zA-Z\s]*$/i

    const [value, setValue] = useState(moment());
    const [fieldDate, setFieldDate] = useState();
    const [openCalendar, setOpenCalendar] = useState();
    const [selectedLocal, setSelectedLocal] = useState();
    const [selectedLocalText, setSelectedLocalText] = useState();
    const [showFalecido, setShowFalecido] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    // get from API
    const [assuntos, setAssuntos] = useState([]);
    const [locais, setLocais] = useState([]);

    const {register,watch, handleSubmit, setError, clearErrors,  formState: { errors }} = useForm({defaultValues:cadastro, shouldFocusError: true, reValidateMode: 'onChange'});
    
    const onSubmit = (data) => {
        if(!recaptcha){
            setError("forms", { type: "focus", message:"Recaptcha Inválido" }, { shouldFocus: true });
            return false;
        }
       
        // manipulating some variables before to make request
        data["HorarioId"] = fieldDate.id
        data["Horario"] =  fieldDate
        data["localText"] = selectedLocalText 
        data["assuntos"] = assuntos
        data["validar"] = true
        API.post('agendamento', data)
        .then(response => {
            if(!response.data.vagaDisponivel){
                setOpenAlert(true);
                return false;
            }
            setCadastro(data)
        })
        .catch(function (error) {
            console.log(error.response)
        });
    };

    // watch changes in assunto
    const watchAssunto = watch("AssuntoId");
    
    const verifyFalecimento = () =>{
        setShowFalecido(false)
        assuntos.map((ass) => {
            if(ass.id == parseInt(watchAssunto)){
                if(ass.falecimento){
                    setShowFalecido(true)
                }
            }
        }) 
    }
    React.useEffect(() => {
        verifyFalecimento()
      }, [watchAssunto]);

    useEffect(() => {
        // used when change step form

        if(cadastro){
            setSelectedLocal(cadastro.localAtendimento)
            setFieldDate(cadastro.Horario)
            setSelectedLocalText(cadastro.localText)
        }
        
	}, [step])

    useEffect(() => {
        API.get('assuntos').then(response => setAssuntos(response.data));
        API.get('locais').then(response => setLocais(response.data));
	}, [])

    useEffect(() => {
        if(fieldDate){
            setOpenCalendar(false);
            clearErrors('HorarioId');
        }
	}, [fieldDate])

    function handleLocal(e){
        setSelectedLocalText(e.target.selectedOptions[0].text)
        setSelectedLocal(e.target.value)   
        clearErrors('localAtendimento');   
    }
    function onChangeRecaptcha(value) {
        if(value){
            clearErrors('forms'); 
            setRecaptcha(true)
        }
    }

    const cpfValidateNumbers = (cpfvalue) => cpf.isValid(cpfvalue);

    return (
        <Form onSubmit={ handleSubmit(onSubmit) }>
            {openAlert ? <ModalAlert hideIcon={true} setOpenAlert={setOpenAlert} title={"Horário indisponível"} message={"A data e horário escolhido já atingiu o limite de vagas disponíveis. Por favor, selecione um novo horário. "} /> : <></>}
            <TitleForm>A CBS Previdência não realiza atendimentos presenciais sem agendamento prévio.</TitleForm>
            <TextNormal>
                Antes de realizar o agendamento, verifique os documentos 
                necessários para o seu atendimento. Sem eles, o seu atendimento não poderá ser realizado.
            </TextNormal>
            <LineDivisor/>
            <RequiredBox>*Preenchimento obrigatório.</RequiredBox>
            <FormRow>
                <FormField>
                    <FormLabel>Assunto*</FormLabel>
                    <FormSelectInput {...register("AssuntoId", { required: "Este campo é obrigatório." })} requiredError={errors.AssuntoId}> 
                        <option value="">Selecione o assunto de seu atendimento</option>
                        { assuntos.map((assunto) => ( <option selected={cadastro && cadastro.AssuntoId == assunto.id} value={assunto.id} key={assunto.id}>{assunto.descricao}</option>)) }
                    </FormSelectInput >
                    {errors.AssuntoId && <ErrorMessage>{errors.AssuntoId.message}</ErrorMessage>}
                </FormField>
            </FormRow>

            <FormRow>
                <FormField>
                    <FormLabel>Nome completo*</FormLabel>
                    <FormInput 
                        placeholder="Digite seu nome" 
                        {...register("Nome", { 
                            required: "Este campo é obrigatório.",
                            maxLength: { value: 255,  message: "Limite máximo de 255 caracteres"},
                            pattern: { value: regexNome, message: "Somente letras são permitidas."},
                            
                        })} 
                        requiredError={errors.Nome}
                    />
                    
                    {errors.Nome && <ErrorMessage>{errors.Nome.message}</ErrorMessage>}
                </FormField>
                <FormSmallField>
                    <FormLabel>CPF*</FormLabel>
                    <InputMask 
                        className="form_input" mask="999.999.999-99" 
                        placeholder="Digite seu CPF" 
                        {...register("CPF", { 
                            required: "Este campo é obrigatório.",
                            pattern: { value: regexCPF, message: "CPF incompleto."} ,                 
                            validate: cpfValidateNumbers
                        })}
                        style={errors.CPF && {"border": `1px solid ${COLORS.danger}`}}
                    />
                    {errors.CPF && <ErrorMessage>{errors.CPF.message}</ErrorMessage>}
                    {errors.CPF && errors.CPF.type === "validate" && <ErrorMessage>CPF inválido.</ErrorMessage>}
                </FormSmallField>
            </FormRow>

            <FormRow>
                <FormSmallField>
                    <FormLabel>Telefone fixo</FormLabel>
                    <InputMask 
                        className="form_input" mask="(99) 9999-9999" 
                        placeholder="Digite seu telefone fixo" {...register("TelefoneFixo", {
                            pattern: { value: regexTelefone, message: "Número de telefone inválido."} 
                        })} 
                        style={errors.TelefoneFixo && {"border": `1px solid ${COLORS.danger}`}}
                    />
                    {errors.TelefoneFixo && <ErrorMessage>{errors.TelefoneFixo.message}</ErrorMessage>}
                </FormSmallField>

                <FormSmallField>
                    <FormLabel>Telefone celular*</FormLabel>
                    <InputMask 
                        className="form_input"  mask="(99) 99999-9999"  
                        placeholder="Digite seu celular" 
                        {...register("TelefoneCelular", { required: "Este campo é obrigatório.",
                            pattern: { value: regexCelular, message: "Número de celular inválido."}  })} 
                        style={errors.TelefoneCelular && {"border": `1px solid ${COLORS.danger}`}}
                    />
                    {errors.TelefoneCelular && <ErrorMessage>{errors.TelefoneCelular.message}</ErrorMessage>}
                </FormSmallField>

                <FormSmallField>
                    <FormLabel>E-mail*</FormLabel>
                    <FormInput 
                        placeholder="Digite seu E-mail" 
                        {...register("Email", { 
                            required: "Este campo é obrigatório.", 
                            pattern: { value: regexEmail, message: "E-mail inválido."} 
                        })}
                        requiredError={errors.Email}
                    />
                    {errors.Email && <ErrorMessage>{errors.Email.message}</ErrorMessage>}
                </FormSmallField>
            </FormRow>

            {
                showFalecido &&
                <FormRow>
                    <FormField>
                        <FormLabel>Nome completo do(a) falecido(a)*</FormLabel>
                        <FormInput 
                            placeholder="Digite o nome do falecido" {...register("FalecidoNome",
                            {
                                required: "Este campo é obrigatório.",
                                maxLength: { value: 255,  message: "Limite máximo de 255 caracteres"},
                                pattern: { value: regexNome, message: "Somente letras são permitidas."}
                            } )} 
                            style={errors.FalecidoNome && {"border": `1px solid ${COLORS.danger}`}}

                        />
                        {errors.FalecidoNome && <ErrorMessage>{errors.FalecidoNome.message}</ErrorMessage>}
                    </FormField>
                    <FormSmallField>
                        <FormLabel>CPF do(a) falecido(a)*</FormLabel>
                        <InputMask 
                            className="form_input" mask="999.999.999-99" 
                            placeholder="Digite o CPF do falecido" {...register("FalecidoCPF", 
                            {
                                required: "Este campo é obrigatório.",
                                pattern: { value: regexCPF, message: "CPF incompleto."} ,  
                                validate: cpfValidateNumbers
                            })}
                            style={errors.FalecidoCPF && {"border": `1px solid ${COLORS.danger}`}}
                        />
                        {errors.FalecidoCPF && <ErrorMessage>{errors.FalecidoCPF.message}</ErrorMessage>}
                        {errors.FalecidoCPF && errors.FalecidoCPF.type === "validate" && <ErrorMessage>CPF inválido.</ErrorMessage>}
                    </FormSmallField>
                </FormRow>
            }
           

            <FormRow>
                <FormField>
                    <FormLabel>Local do atendimento*</FormLabel>
                    <FormSelectInput 
                        {...register("localAtendimento", { required: "Este campo é obrigatório." })} requiredError={errors.localAtendimento}
                        onChange={handleLocal}> 
                        <option value="">Escolha o local do atendimento</option>
                        { locais.map((local) => ( <option selected={cadastro && cadastro.localAtendimento == local.id} value={local.id} key={local.id}>{local.descricao}</option>)) }
                    </FormSelectInput >
                    {errors.localAtendimento && <ErrorMessage>{errors.localAtendimento.message}</ErrorMessage>}
                </FormField>

                <FormSmallField style={{"position":"relative"}}>
                    <FormLabel>Data e horário do atendimento*</FormLabel>
                    <FormInput  
                        autoComplete="off"
                        value={(fieldDate && fieldDate.text) || (cadastro && cadastro.Horario.text)}
                        disabled={!selectedLocal && !cadastro}
                        placeholder="Escolha a data e hora"  
                        {...register("HorarioId", { required: "Este campo é obrigatório.", value: (fieldDate && fieldDate.text)})} 
                        requiredError={errors.HorarioId} 
                        onClick={()=> setOpenCalendar(!openCalendar)}
                    /> 
                    { 
                        openCalendar &&
                        <Calendar value={value} onChange={setValue} setFieldDate={setFieldDate} 
                            openCalendar={openCalendar} setOpenCalendar={setOpenCalendar}
                            selectedLocal={selectedLocal} setSelectedLocal={setSelectedLocal}
                        />
                    }
                    {errors.HorarioId && <ErrorMessage>{errors.HorarioId.message}</ErrorMessage>}
                </FormSmallField>
            </FormRow>

            <FormRow>
                <FormFullField>
                    <FormLabel>Informações Adicionais</FormLabel>
                    <FormTextAreaInput placeholder="Digite as informações" {...register("Informacoes", {
                          maxLength: { value: 300,  message: "Limite máximo de 300 caracteres"} 
                    })} requiredError={errors.Informacoes} />
                    {errors.Informacoes && <ErrorMessage>{errors.Informacoes.message}</ErrorMessage>}
                </FormFullField>
            </FormRow>

            {errors.checkPrivacy && <ErrorMessage>{errors.checkPrivacy.message}</ErrorMessage>}
            <FormRow>
                <FormFullCheckField>
                    <FormInputCheck type="checkbox" id="check_privacy"
                        {...register("checkPrivacy", { required: "Você precisa concordar e aceitar os termos abaixo." })} 
                         requiredError={errors.checkPrivacy}
                    />
                    <LabelFor htmlFor="check_privacy">
                        Ao confirmar o agendamento, você deve estar ciente de que os dados 
                        pessoais fornecidos por você serão gravados no sistema de agendamento
                        da CBS Previdência e tratados pela entidade de acordo com as diretrizes 
                        estabelecidas na sua <ButtonLink target="_blank" href="https://www.cbsprev.com.br/conteudo/downloads/NGCB16.000_PoliticadePrivacidadedoWebsite.pdf">Política de Privacidade</ButtonLink>. Também concorda que a 
                        CBS Previdência poderá entrar em contato com você por telefone, e-mail, 
                        SMS ou aplicativos como o WhatsApp, que se limitarão às comunicações 
                        necessárias com relação ao agendamento.
                    </LabelFor>
                   
                </FormFullCheckField>
                
            </FormRow>
            <FormRow style={{"alignItems": "center", "flexDirection": "column"}}>
                <ReCAPTCHA
                    sitekey="6LfFkhYiAAAAABaA8H21XT9fb1l197CAVE3HCG5n"
                    onChange={onChangeRecaptcha}
                />
            </FormRow>
            <FormRow style={{"alignItems": "center", "flexDirection": "column"}}>      
                <ButtonForm  type="submit" style={{"padding": "12px 80px", "alignItems": "center"}}>Agendar Atendimento</ButtonForm>
                {errors.forms && <ErrorMessage>{errors.forms.message}</ErrorMessage>}
            </FormRow>
        </Form>
    );
}