import React, { Component, useEffect, useState, useRef } from 'react';
import { ButtonForm, ButtonBack, Title, LineDivisor } from "../../../styles/general"
import {
    Container, TextInfo, TextBold, BoxDocuments, DocumentItem,
    ContentButtons
} from "./styles"
import ModalAlert from "../../../components/shared/modal"
import PrintComponent from "../InfoFinal/printComponent"
import { useReactToPrint } from "react-to-print"
import formatDate from "../utils"
import moment from "moment"
import 'moment/locale/pt-br';
import API from "../../../services/api"


moment.locale('pt-BR');

export default function InfoFinalStep({cadastro, cadastroFinalizado }) {
    const [openAlert, setOpenAlert] = useState(false);
    const [wasSend, sendWasSend] = useState(false);

    function sendEmail(){
        if(cadastroFinalizado && cadastroFinalizado.identificador){
            API.post(`agendamento/mail/${cadastroFinalizado.identificador}`)
            .then(response => {
                sendWasSend(true)
                setOpenAlert(true)
            });   
        }
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    return (
        <>
            {openAlert ? <ModalAlert setOpenAlert={setOpenAlert} title={"Agendamento enviado com sucesso para o seu e-mail"} /> : <></>}
            
            <Container>
                
                <div style={{"display": "none"}}>
                    <PrintComponent ref={componentRef} cadastroFinalizado={cadastroFinalizado} cadastro={cadastro}  />
                </div>
               
                <Title>Agendamento efetuado com sucesso!</Title>
                <TextInfo>
                    Confira abaixo os dados do seu agendamento, o assunto agendado e
                    os documentos obrigatórios no dia do seu atendimento presencial.
                </TextInfo>
                <TextInfo>Número do seu Agendamento: <TextBold>{cadastroFinalizado && cadastroFinalizado.identificador}</TextBold></TextInfo>
                <DocumentItem>Local do atendimento: <TextBold>{cadastroFinalizado && cadastroFinalizado.localHorario.local}</TextBold></DocumentItem>
                <DocumentItem>Data e horário do atendimento: <TextBold>{cadastro && formatDate(cadastro.Horario)}</TextBold></DocumentItem>
                <DocumentItem>Assunto agendado: <TextBold> {cadastroFinalizado && cadastroFinalizado.assunto.descricao} </TextBold></DocumentItem>

                <DocumentItem>Documentos necessários: </DocumentItem>
                <BoxDocuments>
                    {
                        cadastroFinalizado &&
                        cadastroFinalizado.assunto.documentos.map((doc, index) => (
                            <DocumentItem key={index}>{doc}</DocumentItem>
                        ))
                    }
                    
                </BoxDocuments>
                <br />
                <br />
                <TextInfo>
                    Lembre-se dos documentos que deverão ser apresentados no dia do seu atendimento.
                    Sem eles, o seu atendimento não poderá ser realizado.
                </TextInfo>

                <ContentButtons>
                    <ButtonForm bgColor={wasSend && "#57a47e"} style={{ "flex": "0.4" }} onClick={() => !wasSend && sendEmail()}> {wasSend ? "E-mail enviado"  : "Receber por E-mail"} </ButtonForm>
                    <ButtonForm style={{ "flex": "0.2" }} onClick={() => handlePrint()}>Imprimir</ButtonForm>
                    <ButtonBack href="/" style={{ "flex": "0.14" }}>Voltar</ButtonBack>
                </ContentButtons>

                <LineDivisor style={{ "margin": "20px 0px" }} />

                <TextBold>Informações importantes:</TextBold>
                <br />
                <DocumentItem>
                    Aguardamos sua presença. Caso não possa comparecer na data e no horário agendado,
                    envie e-mail para <a href="mailto:cbsagendamento@gmail.com">cbsagendamento@gmail.com</a> solicitando o cancelamento do seu agendamento
                    e faça novo agendamento, se necessário.
                </DocumentItem>
                <br />
                <DocumentItem>
                    Lembre-se de levar seu documento oficial com
                    foto (identidade ou habilitação) e os documentos exigidos para o atendimento do assunto agendado.
                </DocumentItem>
                <br />
                <DocumentItem>Se possível, não compareça com acompanhantes para evitar aglomeração. </DocumentItem>
                <br />
                <DocumentItem>
                    O agendamento poderá ser cancelado pela CBS, caso o assunto
                    agendado não esteja compatível com a situação do participante
                    na CBS (ativo ou assistido), por restrições decorrentes da pandemia
                    do COVID-19 ou por situações alheias à nossa vontade. Nesses casos,
                    a CBS Previdência notificará o cancelamento por meio do envio de
                    mensagem de SMS para o número do celular informado no agendamento.
                    Portanto, fique atento às mensagens de SMS recebidas pelo seu celular.
                </DocumentItem>
                <br />
                <br />
            </Container>
        </>
    )

}