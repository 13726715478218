import React, { useState, useEffect } from "react"
import "./styles.css"

import buildCalendar from "./build"
import dayStyles, { beforeToday } from "./styles"
import Header from "./header"
import moment from "moment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { COLORS } from "../../styles/colors"
import API from "../../services/api"

export default function Calendar({ value, onChange, setFieldDate, setOpenCalendar, selectedLocal, openCalendar }) {
	// pass this info to parent compontent

	const [calendar, setCalendar] = useState([]);
	const [hours, setHours] = useState([]);
	const [selectedDate, setSelectedDate] = useState();
	const [selectedHour, setSelectedHour] = useState();
	const [selectedHourId, setSelectedHourId] = useState();
	const [openConfirmButton, setConfirmButton] = useState();
	const [openCancelButton, setCancelButton] = useState(true);

	// storage all available hours from API
	const [dates, setDates] = useState([]);

	useEffect(() => {
		setCalendar(buildCalendar(value));
		updateAvailableHours(value);
	}, [value,dates])

	useEffect(() => {
		API.get(`local/${selectedLocal}/horarios?dias=50`).then(response => { setDates(response.data) });
	}, [selectedLocal])

	function prevWeek() {
		return value.clone().subtract(1, "day")
	}

	function nextWeek() {
		return value.clone().add(1, "day")
	}

	function thisWeek() {
		return value.isSame(new Date(), "day")
	}

	function clearHours() {
		// cleaning and reset all hours info  
		setHours([])
		setSelectedHourId()
		setSelectedHour()
	}
	function updateAvailableHours(day) {
		clearHours()
		setConfirmButton(false)
		setSelectedDate(day)

		// Set available hour boxes based in API response in State
		dates.map((date) => {
			if (day.isSame(moment(date.data), "day")) {
				date.horarios.map((hour) => {
					setHours(state => [...state, hour])
				})
			}
		})
	}

	function selectHour(hour) {
		setSelectedHourId(hour.id)
		setSelectedHour(hour)
		setConfirmButton(true)
	}

	function updateField() {
		let formatted_date = `${selectedDate.format("DD/MM/YYYY")} ${selectedHour.horario}`
		let obj = { text: formatted_date, id: selectedHourId, hour: selectedHour.horario, date: selectedDate }
		setFieldDate(obj)
	}

	function cancelField() {
		setSelectedHourId()
		setSelectedHour()
		setOpenCalendar(false)
		setFieldDate({ text: "", id: 0, hour: "", date: "" })
	}

	return (
		<div className="calendar">
			<Header value={value} setValue={onChange} />
			<div className="calendar_inner">
				<div className="prevDay arrow arrow-day" onClick={() => !thisWeek() && onChange(prevWeek())}> {!thisWeek()
					? <FontAwesomeIcon color={COLORS.primary} icon={faChevronLeft} />
					: <FontAwesomeIcon color={COLORS.greycolor} icon={faChevronLeft} />}
				</div>
				<div className="bodyCalendar">
					<div className="day_names noselect">
						{["D", "S", "T", "Q", "Q", "S", "S"].map((d, index) => <div key={index} className="week">{d}</div>)}
					</div>

					<div className="box_days">
						{calendar.map((week) => (
							<div className="day_container noselect">
								{week.map((day, index) => (
									<div key={index} className="day noselect" onClick={() => !beforeToday(day) && onChange(day)}>
										<div className={dayStyles(day, value)}>
											{day.format("D").toString()}
										</div>

									</div>
								))}
							</div>
						))}
					</div>
				</div>
				<div className="nextDay arrow arrow-day" onClick={() => onChange(nextWeek())}>
					<FontAwesomeIcon color={COLORS.primary} icon={faChevronRight} />
				</div>
			</div>
			<div className="calendar_hours">
				<p className="hours_title noselect">Horários Disponíveis</p>
				<div className="calendar_available_hours noselect">
					{
						hours.length > 0
							? hours.map((hour, index) => (
								<span key={index} className={selectedHourId == hour.id ? "noselect selected" : "noselect"} onClick={() => selectHour(hour)}>{hour.horario}</span>
							))
							: <div className="empty_hours_box"><p className="empty_hours">Nenhum horário disponível</p></div>
					}
				</div>
			</div>

			<div className="calendar_buttons">
				{
					openConfirmButton &&
					<button type="button" className="calendar_button confirm_button noselect" onClick={() => updateField()}>Confirmar</button>
				}
				{
					openCancelButton && !openConfirmButton &&
					<button  style={{"width": "100%"}} type="button" className="calendar_button cancel_button noselect" onClick={() => cancelField()}>Cancelar</button>
				}

				{
					openConfirmButton &&
					<button  style={{"width": "50%"}}  type="button" className="calendar_button cancel_button noselect" onClick={() => cancelField()}>Cancelar</button>
				}
			</div>


		</div >
	)
}