import React, { Component } from 'react';
import {
    FooterSection, FooterTop, FooterBox,
    FooterText, FooterIcon, FooterMid, FooterList, 
    FooterListItem, FooterListItemLink, FooterBottom
} from "./styles";
import { Center } from "../../../styles/general";

class Footer extends Component {
    render() {
        return (
            <FooterSection>
                <Center>
                    <FooterTop>
                        <FooterBox>
                            <FooterIcon src="/images/icons/pin.png" />
                            <FooterText>
                                Av. Doutor Cardoso de Melo, n° 1.855 - 7° andar,<br />
                                Conjunto 72, Edifício Francisco Lopes - Vila Olímpia<br />
                                04548-903 - São Paulo - SP | CBS Previdência
                            </FooterText>
                        </FooterBox>

                        <FooterBox>
                            <FooterIcon src="/images/icons/callcenter.png" />
                            <FooterText style={{ "fontWeight": "bold" }}>Central de<br />Atendimento<br />Telefônico</FooterText>
                            <FooterText style={{ "fontSize": "20px", "marginLeft": " 15px" }}><strong>0800 026 81 81</strong></FooterText>
                        </FooterBox>

                        <FooterBox>
                            <FooterIcon src="/images/icons/email.png" />
                            <FooterText style={{ "fontWeight": "bold" }}>E-mail <br />cbsatendimento@cbsprev.com.br</FooterText>
                        </FooterBox>
                    </FooterTop>

                    <FooterMid>
                        <FooterList>
                            <FooterListItem>
                                <FooterListItemLink target="_blank" href="https://www.cbsprev.com.br/lgpd" target="_blank">
                                    Proteção de Dados
                                </FooterListItemLink>
                            </FooterListItem>
                            <FooterListItem>
                                <FooterListItemLink target="_blank" href="https://www.cbsprev.com.br/Outros/CanalDeDenuncia">
                                    Canal de denúncias
                                </FooterListItemLink>
                            </FooterListItem>
                            <FooterListItem>
                                <FooterListItemLink target="_blank" href="https://www.cbsprev.com.br/Outros/DuvidasFrequentes">
                                    Dúvidas Frequentes
                                </FooterListItemLink>
                            </FooterListItem>
                            <FooterListItem>
                                <FooterListItemLink target="_blank" href="https://www.cbsprev.com.br/Outros/Glossario">
                                    Glossário
                                </FooterListItemLink>
                            </FooterListItem>
                            <FooterListItem>
                                <FooterListItemLink target="_blank" href="https://www.cbsprev.com.br/Outros/linksuteis">
                                    Links Úteis
                                </FooterListItemLink>
                            </FooterListItem>
                        </FooterList>
                    </FooterMid>
                
                    <FooterBottom>
                        <FooterText>Todos os direitos reservados</FooterText>
                        <a target="_blank" href="https://www.am4.com.br/">
                            <img src="/images/icons/am4-logo.png"/>
                        </a>
                    </FooterBottom>
                </Center>
            </FooterSection>
        )
    }
}

export default Footer;


