import styled from "styled-components";
import { COLORS } from "../../styles/colors";


export const AsideSteps = styled.aside`
    display:flex;
    flex-direction:column;
    padding: 32px 0px;
    margin-right: 50px;
    flex: 0.2;

    @media(max-width: 1000px) {
        flex: 1;
        margin-right: 0px;
    }
`

export const Content = styled.div`
    display:flex;
    background: ${COLORS.white};
    width: 100%;
    flex: 0.8;
    padding: 40px 25px;

    @media(max-width: 1000px) {
        padding: 10px;
        flex: 1;
    }
`

export const Container = styled.div`
    display:flex;

    @media(max-width: 1000px) {
        flex-direction: column;
    }
`


export const StepContent = styled.div`
    display:flex;
    flex-direction: column;

    @media(max-width: 1000px) {
        flex-direction: row;
        justify-content: space-evenly;
    }
`

export const Step = styled.div`
    display:flex;
    align-items: center;
    color: ${COLORS.greycolor};
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin: 11px 0px;
    position: relative;


    &:not(:last-child):before {
        content: '';
        position: absolute;
        left: 13px;
        bottom: -12px;
        width: 22px;
        height: 2px;
        background: ${COLORS.greycolor};
        transform: rotate(90deg);
    }

    @media(max-width: 1000px) {

        flex-direction: column;

    
        &:not(:last-child):before {
            display:none;
        }
    }
`

export const StepActive = styled.div`
    display:flex;
    align-items: center;
    color: ${COLORS.primary};
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin: 11px 0px;
    position: relative;

    &:not(:last-child):before {
        content: '';
        position: absolute;
        left: 13px;
        bottom: -12px;
        width: 22px;
        height: 2px;
        background: ${COLORS.primary};
        transform: rotate(90deg);
    }

    @media(max-width: 1000px) {

        flex-direction: column;
        
        &:not(:last-child):before {
            display:none;
        }
    }
`

export const CircleStep = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid ${COLORS.greycolor};
    display:flex;
    justify-content: center;
    align-items: center;
    color: ${COLORS.greycolor};
    font-weight: bold;
    margin-right: 10px;

    @media(max-width: 1000px) {
        margin-right: 0px;
    }
`

export const CircleStepActive = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${COLORS.primary};
    display:flex;
    justify-content: center;
    align-items: center;
    color: ${COLORS.white};
    font-weight: bold;
    margin-right: 10px;

    @media(max-width: 1000px) {
        margin-right: 0px;
    }
`
