import React, { Component } from 'react';
import { HeaderBox } from "./styles";
import { Center } from "../../../styles/general";

class Header extends Component {
    render() {
        return (
            <HeaderBox>
                <Center>
                    <a href="https://www.cbsprev.com.br/">
                        <img src="/images/logo.png" />
                    </a>
                </Center>
            </HeaderBox>
        )
    }
}

export default Header;


