import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import {GlobalStyle} from './styles/global'
import Home from "./pages/Home/home"
import Scheduler from "./pages/Scheduling"
import Cookie from "./components/shared/cookies"

export default function App() {
  return (
    <Router>
        <GlobalStyle/>
        <Switch>
          <Route path="/agendamento">
            <Scheduler />
            <Cookie />
          </Route>

          <Route path="/">
            <Home />
            <Cookie />
          </Route>
        </Switch>
    </Router>
  );
}
