import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const ContentModalBackground = styled.div`
    background-color: #000c;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 10;
    justify-content: center;
    padding: 40px;
    overflow-y: auto;
`

export const ModalBox = styled.div`
    background-color: ${COLORS.white};
    width: 50%;
    padding: 35px 50px;
    display:flex;
    flex-direction: column;
    border-radius: 8px;
    height: 100%;
    
    @media(max-width: 1000px) {
        width: 100%;
    }

    @media(max-width: 400px) {
        padding: 40px 30px;
    }

    :-webkit-scrollbar {
        width: 5px;
    }

    :-webkit-scrollbar-track {
        border: 1px solid #000;
        padding: 2px 0;
        background-color: #404040;
    }
`

export const ModalContent = styled.div`
    width: 100%;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`

export const ModalTitle = styled.h1`
    color: ${COLORS.primary};
    font-weight: 400;
    margin-bottom: 30px;
`

export const ModalText = styled.p`
    font-size: 16px;
    color: ${COLORS.secondary};
`

export const SubjectContent = styled.section`
    display:flex;
    width:100%;
    margin: 30px 0px 0px 0px;
    flex-direction: column;
`

export const SubjectItem = styled.p`
    color: ${COLORS.secondary};
    font-size: 16px;
    width: 100%;
    display:flex;
    align-items: center;
    padding: 5px 0px;
    display: list-item;
    margin-left: 18px;
    line-height: 20px;
    padding-right: 30px;
`

export const SubjectTitle = styled.p`
    color: ${COLORS.secondary};
    font-size: 16px;
    width: 100%;
    font-weight: bold;
    display:flex;
    align-items: center;
    margin-bottom: 10px;
`

export const ContentButtons = styled.div`
    display:flex;
    flex-direction: row;
    margin: 15px 0px;
    justify-content: space-evenly;
`
