import {COLORS} from './colors';
import styled from "styled-components";

export const Center = styled.div`
    margin: 0 auto;
    width: 1050px;

    @media(max-width: 1000px) {
        width: 100%;
        padding: 20px;
    }

`

export const Button = styled.a`
    background-color: ${COLORS.buttonColor};
    padding: 12px 30px;
    width: fit-content;
    text-transform: uppercase;
    color: ${COLORS.white};
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    transition: 0.4s;
    text-decoration: none;

    &:hover{
        color: ${COLORS.buttonColor};
        background-color: ${COLORS.white}
    }
`

export const ButtonForm = styled.button`
    background-color: ${props => props.bgColor || COLORS.buttonColor};
    padding: 12px 30px;
    width: fit-content;
    text-transform: uppercase;
    color: ${COLORS.white};
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    transition: 0.4s;
    text-decoration: none;
    border:none;

    &:hover{
        color: ${COLORS.buttonColor};
        background-color: ${COLORS.white}
    }

    @media(max-width: 1000px) {
        padding: 12px 30px !important;
        width: 100%;
        margin-bottom: 10px;
    }
`
export const ButtonBack = styled.a`
    background-color: ${COLORS.greyBoxColor};
    padding: 12px 30px;
    width: fit-content;
    text-transform: uppercase;
    color: ${COLORS.secondary};
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    transition: 0.4s;
    text-decoration: none;
    border:none;
    text-align: center;
    display: flex;
    align-items:center;
    justify-content: center;

    &:hover{
        color: ${COLORS.secondary};
        background-color: ${COLORS.white}
    }

    @media(max-width: 1000px) {
        width: 100%;
        margin-bottom: 10px;
    }
`

export const Title = styled.h1`
    color: ${COLORS.primary};
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 15px;
`
export const SubTitle = styled.h2`
    color: ${COLORS.primary};
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
`
export const Text = styled.p`
    color: ${COLORS.secondary};
    font-size: 16px;
    line-height:18px;
`

export const TextBold = styled.b`
    font-weight: bold;
`

export const BlankSpace = styled.div`
    height: 30px;
    width: 100%;
`

export const LineDivisor = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${COLORS.secondary};
    margin: 40px 0px;
    opacity: 0.2
`
