import React, { Component } from 'react';
import {
    ModalBox, ModalTitle, ModalText, ContentModalBackground, CenterAlert, ModalImage
} from "./styles";
import { ButtonForm } from "../../../styles/general"


export default function ModalAlert({message, title, setOpenAlert, hideIcon}) {
    
    return (
        <ContentModalBackground>
            <ModalBox>
                <CenterAlert>
                    {hideIcon ? <></> : <ModalImage src="/images/icons/check.png" />}
                    
                    <ModalTitle>{title}</ModalTitle>
                    <ModalText>{message}</ModalText>
                    <br/>
                    <ButtonForm style={{"padding": "12px 55px"}} onClick={() => setOpenAlert(false)}>OK</ButtonForm>
                </CenterAlert>
            </ModalBox>
        </ContentModalBackground>
    )
    
}


