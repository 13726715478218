import React, { Component } from 'react';
import {
    ModalBox, ModalTitle, ModalContent,
    ModalText, SubjectContent, SubjectTitle, SubjectItem, ContentModalBackground, ContentButtons
} from "./styles";
import { ButtonForm } from "../../styles/general"
import API from "../../services/api"


class ModalDocuments extends Component {

    state = { assuntos: [] }

    componentDidMount() {
        API.get("assuntos")
          .then(res => {
            const assuntos = res.data;
            this.setState({ assuntos });
        })
    }
    render() {
        var { assuntos } = this.state;
        return (
            <ContentModalBackground>
                <ModalBox>
                    <ModalTitle>Documentos necessários para os atendimentos</ModalTitle>
                    <ModalContent>
                        <ModalText>Consulte a lista completa dos documentos exigidos para cada assunto disponível para agendamento:</ModalText>
                        {
                            assuntos && assuntos.map((assunto, index) => (
                                <SubjectContent>
                                    <SubjectTitle>{index +1}. {assunto.descricao}</SubjectTitle>
                                    {
                                        assunto.documentos.map((doc, index) => (
                                            <SubjectItem>{doc}</SubjectItem>
                                        ))
                                    }
                                    
                                </SubjectContent>
                            ))
                        }
                      

                    </ModalContent>

                    <ContentButtons>
                        <ButtonForm style={{"padding": "12px 55px"}} onClick={() => this.props.handleModal(false)} >OK</ButtonForm>
                    </ContentButtons>

                </ModalBox>
            </ContentModalBackground>
        )
    }
}

export default ModalDocuments;


