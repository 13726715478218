import styled from "styled-components";
import { COLORS } from "../../styles/colors";


export const Container = styled.section`
    display:flex;
    justify-content: center;   
    width:100%; 
    min-height: 420px;
    background-color: ${COLORS.white};
`
export const ContentLeft = styled.section`
    display:flex;
    width: 100%;
    justify-content: flex-end;

`
export const ContentRight = styled.section`
    display:flex;
    background-image: url("/images/welcome.png");
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;

    @media(max-width: 1000px) {
        display: none
    }
`
export const ContentBox = styled.section`
    display:flex;
    flex-direction:column;
    width: 520px;
    padding-right: 180px;
    justify-content: center;

    @media(max-width: 1000px) {
        width: 100%;
        padding: 20px;
    }
`

export const GreyBox = styled.section`
    display:flex;
    justify-content: center;   
    width:100%;
    background-color: ${COLORS.boxColor};
`

export const TextGrey = styled.p`
    color: ${COLORS.secondary};
    font-size: 16px;
    line-height:20px;
    padding: 24px 0px;
`

export const SubjectContainer = styled.section`
    display:flex;
    justify-content: center;   
    width:100%;
    padding: 30px 0px;
    background-color: ${COLORS.white};
`

export const SubjectContent = styled.section`
    display:flex;
    margin-top: 40px;
    justify-content: space-between;

    @media(max-width: 1000px) {
        flex-direction: column;
    }
`

export const TextHomeDefault = styled.p`
    color: ${COLORS.secondary};
    font-size: 16px;
    line-height:22px;
`


export const SubjectLeftBox = styled.div`
    display:flex;
    flex-direction: column;  
    width: 100%;
    margin-right: 22px;
`
export const SubjectRightBox = styled.div`
    display:flex;
    justify-content: flex-start;
    flex-wrap:wrap;

    @media(max-width: 1000px) {
        margin-top: 30px;
        padding: 0px 20px;
    }
`
export const SubjectItem = styled.p`
    color: ${COLORS.secondary};
    font-size: 15px;
    width: 47%;
    font-weight: bold;
    display:flex;
    align-items: center;
    padding: 0px 0px 15px 0px;
    display: list-item;
    margin-right: 40px;

    @media(max-width: 425px) {
        width: 100%;
        margin-right: 0px;
    }
    
    :nth-child(even){
        margin-right: 0px;
    }

`

export const ButtonLink = styled.a`
    width:100%;
    color: ${COLORS.primary};
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
    font-size: 17px;
`

