import styled from "styled-components";
import { COLORS } from "../../../../styles/colors";


export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;

    @media(max-width: 1000px) {
        padding: 10px;
    }
`

export const TextInfo = styled.p`
    font-size: 16px;
    color: ${COLORS.labelColor};
    line-height: 26px;
    width: 100%;

    :first-of-type{
        margin: 30px 0px;
    }
`
export const BoxDocuments = styled.div`
    display:flex;
    flex-direction: column;
    margin-left: 40px;
    font-weight: bold;
    margin-bottom: 30px;
`

export const TextBold = styled.b`
  color: ${COLORS.secondary};
  font-size: 16px;
`

export const ContentButtons = styled.div`
    display:flex;
    flex-direction: row;
    margin: 32px 0px;
    justify-content: space-evenly;

    @media(max-width: 1000px) {
        flex-direction: column;
    }
`

export const DocumentItem = styled.p`
    color: ${COLORS.secondary};
    font-size: 16px;
    display:flex;
    align-items: center;
    display: list-item;
    margin-left: 16px;
    line-height: 26px;
`