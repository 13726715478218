import React, { Component, useEffect, useState } from 'react';
import {
    CookiesSection, TextCookie, ContentButtons, Button, CookiesInner
} from "./styles";
import { Center } from "../../../styles/general";
import { useCookies } from "react-cookie";

export default function Cookie(){
    const [cookies, setCookie] = useCookies(["acceptCookie"]);
    const [opened, setOpened] = useState(true);

    useEffect(() => {
        if(cookies.acceptCookie){
            setOpened(false)
        }
       
	}, [cookies])

    function enableCookies(){
        setCookie("acceptCookie", "true", {
            path: "/"
        });
        setOpened(false)
    }
    return (
        <CookiesSection openModal={opened}>
            <Center>
                <CookiesInner>
                    <TextCookie>
                        Utilizamos cookies por diversos motivos, entre eles, para melhorar a sua experiência 
                        de navegação em nosso site. Você pode aceitar ou recusar a utilização dos cookies. 
                        Se recusar, não poderemos te proporcionar uma experiência individualizada, 
                        mas isso não afetará o uso das funcionalidades disponíveis. Escolha um dos 
                        botões para prosseguir. Para mais detalhes, consulte a nossa <a href="https://www.cbsprev.com.br/conteudo/downloads/NGCB16.000_PoliticadePrivacidadedoWebsite.pdf" target="_blank">Política de Privacidade</a>.      
                    </TextCookie>
                    <ContentButtons>
                        <Button onClick={() => enableCookies()}>Aceitar</Button>
                        <Button onClick={() => enableCookies()}> Recusar</Button>
                    </ContentButtons>
                </CookiesInner>
            </Center>
        </CookiesSection>
    )
    
}
