import React, { Component, useState, useEffect  } from 'react';
import { Button, Title, Text, Center, TextBold, BlankSpace, LineDivisor } from "../../styles/general"
import {
    AsideSteps, StepContent, Step, CircleStep, Content, Container,
    StepActive, CircleStepActive,
} from "./styles"

import FormStep from "./Form"
import ConfirmStep from "./Confirm"
import InfoFinalStep from "./InfoFinal"
import Header from "../../components/shared/header"
import Footer from "../../components/shared/footer"

const Scheduler = () => {
    const [cadastro, setCadastro] = useState();
    const [cadastroFinalizado, setCadastroFinalizado] = useState();
    const [step, setStep] = useState(1);

    useEffect(() => {
        if(cadastro){
            setStep(2)
        }
	}, [cadastro])


    function getStep(stepNumber, name){
        return stepNumber <= step 
        ? <StepActive><CircleStepActive>{stepNumber}</CircleStepActive>{name}</StepActive>
        :  <Step><CircleStep>{stepNumber}</CircleStep>{name}</Step>
    }

    return (
        <>
            <Header />
            <Center>
                <Container>
                    <AsideSteps>
                        <Title>Agende seu atendimento presencial</Title>
                        <Text style={{ "fontSize": "14px" }}>Preencha corretamente os campos ao lado até completar todos os passos.</Text>
                        <BlankSpace />
                        <StepContent>
                            { getStep(1, "Agendar")}
                            { getStep(2, "Confirmar")}
                            { getStep(3, "Imprimir")}
                        </StepContent>
                    </AsideSteps>

                    <Content>
                        {step && step == 1 ? <FormStep cadastro={cadastro} setCadastro={setCadastro} setStep={setStep} step={step} /> : null}
                        {step && step == 2 ? <ConfirmStep cadastro={cadastro} setStep={setStep} setCadastro={setCadastro} setCadastroFinalizado={setCadastroFinalizado} /> : null}
                        {step && step == 3 ? <InfoFinalStep cadastro={cadastro} setStep={setStep}  cadastroFinalizado={cadastroFinalizado} /> : null}                        
                    </Content>
                </Container>
            </Center>
            <Footer />
        </>
    )
}


class teste extends Component {
    render(){
        return("teste")
    }
}


export default Scheduler;