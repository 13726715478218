import { createGlobalStyle } from 'styled-components'
import { COLORS } from "./colors"
export const GlobalStyle =  createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    input,button{
        outline: 0;
        box-sizing: border-box;
    }
    html{
        font-size: 56.25%;
        background-color: #E5E6E880;
    }

    body{
        font-size: 1.6rem;
        background-color: #E5E6E880;
        font-family: Arial
    }

    ::-webkit-scrollbar {
        width: 3px;
        background: #f1f1f1; 
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #002F6B; 
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: #002F6B; 
    }

    .form_input{
        width: 100%;
        border-radius: 8px;
        border: 1px solid #D2D2D2;
        height: 48px;
        color: #707070;
        font-size: 16px;
        padding: 0px 12px;

    }
  
    ::placeholder {
        color: #D2D2D2 !important;
    }
    @media print {
        html,
        body {
            height: 100% !important;
            background-color: white;
            -webkit-print-color-adjust: exact !important;
        }

        .page-break {
            margin-top: 1rem;
            display: block;
            page-break-after: always;
        }
        
        .no-print{
            display: none;
        }

    }

    @page {
        size: auto;
        margin: 20mm;
    }
    

`;
