import styled from "styled-components";
import { COLORS } from "../../../styles/colors";

export const ContentModalBackground = styled.div`
    background-color: #000c;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 10;
    justify-content: center;
    padding: 40px;
    left: 0;
    top: 0;
    align-items: center;
`

export const ModalBox = styled.div`
    background-color: ${COLORS.white};
    padding: 35px 35px;
    display:flex;
    flex-direction: column;
    border-radius: 8px;
    width: 20%;

    @media(max-width: 1000px) {
        width: 60%;
    }

    @media(max-width: 400px) {
        width: 100%;
        padding: 30px;
    }
`

export const ModalImage = styled.img`
    width: 90px;
    margin: 25px 0px;
`
export const CenterAlert = styled.div`
    margin: 0 auto;
    display:flex;
    flex-direction: column;
    align-items:center;

`
export const ModalTitle = styled.h1`
    color: ${COLORS.secondary};
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 16px;
    text-align: center;
`

export const ModalText = styled.p`
    font-size: 16px;
    color: ${COLORS.secondary};
`
