import React, { Component } from 'react'
import { ButtonForm, ButtonBack, Title, LineDivisor } from "../../../../styles/general"
import {
    Container, TextInfo, TextBold, BoxDocuments, DocumentItem,
    ContentButtons
} from "./styles"
import moment from "moment"
import 'moment/locale/pt-br';
moment.locale('pt-BR');


class PrintComponent extends Component {


    formatDate = (horario) => {
        let date = moment(horario.date)
        return `${date.format('dddd')}, ${date.format("LL")}, ${horario.hour} `
    }

    render() {
        var { cadastroFinalizado, cadastro } = this.props;
        return (
            <Container>
                <Title>Agendamento efetuado com sucesso!</Title>
                <TextInfo>
                    Confira abaixo os dados do seu agendamento, o assunto agendado e
                    os documentos obrigatórios no dia do seu atendimento presencial.
                </TextInfo>
                <TextInfo>Número do seu Agendamento: <TextBold>{cadastroFinalizado && cadastroFinalizado.identificador}</TextBold></TextInfo>
                <DocumentItem>Local do atendimento: <TextBold>{cadastroFinalizado && cadastroFinalizado.localHorario.local}</TextBold></DocumentItem>
                <DocumentItem>Data e horário do atendimento: <TextBold>{cadastro && this.formatDate(cadastro.Horario)}</TextBold></DocumentItem>
                <DocumentItem>Assunto agendado: <TextBold> {cadastroFinalizado && cadastroFinalizado.assunto.descricao} </TextBold></DocumentItem>

                <DocumentItem>Documentos necessários: </DocumentItem>
                <BoxDocuments>
                    {
                        cadastroFinalizado &&
                        cadastroFinalizado.assunto.documentos.map((doc, index) => (
                            <DocumentItem key={index}>{doc}</DocumentItem>
                        ))
                    }

                </BoxDocuments>

                <TextInfo>
                    Lembre-se dos documentos que deverão ser apresentados no dia do seu atendimento.
                    Sem eles, o seu atendimento não poderá ser realizado.
                </TextInfo>

                <LineDivisor style={{ "margin": "20px 0px" }} />

                <TextBold>Informações importantes:</TextBold>
                <br />
                <DocumentItem>
                    Aguardamos sua presença. Caso não possa comparecer na data e no horário agendado,
                    envie e-mail para cbsagendamento@gmail.com solicitando o cancelamento do seu agendamento
                    e faça novo agendamento, se necessário.
                </DocumentItem>
                <br />
                <DocumentItem>
                    Lembre-se de levar seu documento oficial com
                    foto (identidade ou habilitação) e os documentos exigidos para o atendimento do assunto agendado.
                </DocumentItem>
                <br />
                <DocumentItem>Se possível, não compareça com acompanhantes para evitar aglomeração. </DocumentItem>
                <br />
                <DocumentItem>
                    O agendamento poderá ser cancelado pela CBS, caso o assunto
                    agendado não esteja compatível com a situação do participante
                    na CBS (ativo ou assistido), por restrições decorrentes da pandemia
                    do COVID-19 ou por situações alheias à nossa vontade. Nesses casos,
                    a CBS Previdência notificará o cancelamento por meio do envio de
                    mensagem de SMS para o número do celular informado no agendamento.
                    Portanto, fique atento às mensagens de SMS recebidas pelo seu celular.
                </DocumentItem>
               
            </Container>
        )
    }
}

export default PrintComponent;