import React, { Component } from 'react';
import { Button, Title, Text, Center, TextBold} from "../../styles/general"
import {
    Container, 
    ContentLeft,
    ContentRight,
    ContentBox,
    GreyBox,
    TextGrey,
    SubjectContainer,
    SubjectLeftBox,
    SubjectRightBox,
    SubjectItem,
    ButtonLink,
    SubjectContent,
    TextHomeDefault

} from "./styles"

import Header from "../../components/shared/header"
import Footer from "../../components/shared/footer"
import ModalDocuments from "../../components/modal_documents"
import API from "../../services/api"

class Home extends Component {
    constructor(props){
        super(props)
    }

    state = { openModal: false, assuntos: [] }

    handleModal = (opened) => {
        this.setState({
            openModal: opened
        })
    }

    componentDidMount() {
        API.get("assuntos")
          .then(res => {
            const assuntos = res.data;
            this.setState({ assuntos });
        })
    }

      
    render() {
        var { openModal, assuntos } = this.state;
        return (
            <>
            { openModal ?  <ModalDocuments handleModal={this.handleModal} /> : <></> }
            
            <Header/>
            <Container>
               <ContentLeft>
                    <ContentBox>
                        <Title>Olá! Seja bem-vindo.<br/>Agende seu atendimento presencial pela nossa plataforma online</Title>
                        <Text style={{"marginBottom":"32px", "lineHeight": "20px"}}>Aqui você poderá agendar o seu atendimento presencial na CBS Previdência. 
                           Para isso, basta clicar no botão a seguir, preencher as informações e escolher o assunto desejado.</Text>
                        <Button href="/agendamento">Agende seu Atendimento</Button>
                    </ContentBox>
               </ContentLeft>
               <ContentRight/>
               
            </Container>

            <GreyBox>
                <Center>
                    <TextGrey>
                        <TextBold>Fique atento:</TextBold> o atendimento presencial é exclusivo para os assuntos destacados a seguir. 
                        Caso queira tratar de outros assuntos, envie um e-mail para cbsatendimento@cbsprev.com.br ou 
                        ligue para a nossa Central de Atendimento Telefônico (0800 026 81 81), 
                        que funciona de segunda a sexta-feira, das 08h às 17h.
                    </TextGrey>
                </Center>
            </GreyBox>

            <SubjectContainer>
                <Center>
                    <Title>Confira os assuntos disponíveis para agendar o seu atendimento presencial</Title>
                    <SubjectContent>
                        <SubjectLeftBox>
                            <TextHomeDefault>Consulte também os <TextBold>documentos necessários</TextBold> para cada assunto.</TextHomeDefault>
                            <ButtonLink onClick={()=>{this.setState({openModal: true})}}>Ver lista »</ButtonLink>
                        </SubjectLeftBox>
                        <SubjectRightBox>
                            {
                                assuntos && assuntos.map((assunto, index)=> (
                                    <SubjectItem key={index}>{assunto.descricao}</SubjectItem>
                                ))
                            }
                        </SubjectRightBox>
                    </SubjectContent>
                </Center>
            </SubjectContainer>
            <Footer/>
            </>
        )
    }
}

export default Home;


