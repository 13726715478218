import styled from "styled-components";
import { COLORS } from "../../../styles/colors";


export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
`

export const BoxContainer = styled.div`
    display:flex;
    flex-direction: column;
    background-color: #002F6B1A;
    border: 1px solid #002F6B;
    width: 100%;
    border-radius: 8px;
    padding: 30px;
`

export const TextInfo = styled.p`
    font-size: 16px;
    color: ${COLORS.labelColor};
    line-height: 22px;
    width: 100%;

    :not(:first-child){
        margin-top: 30px;
    }
`

export const TextBold = styled.b`
  color: ${COLORS.primary};
  text-transform: uppercase;

`
export const BoxDocuments = styled.div`
    display:flex;
    flex-direction: column;
    margin-top: 10px;
`

export const ContentButtons = styled.div`
    display:flex;
    flex-direction: row;
    margin: 32px 0px;
    justify-content: space-between
`

export const DocumentItem = styled.p`
    color: ${COLORS.primary};
    font-size: 16px;
    font-weight: bold;
    display:flex;
    align-items: center;
    display: list-item;
    line-height: 22px;
    text-transform: uppercase;
    margin-left: 80px;
    margin-bottom: 10px;
`