import React, { Component, useEffect, useState } from 'react';
import { ButtonForm, ButtonBack } from "../../../styles/general"
import {
    Container, BoxContainer, TextInfo, TextBold, BoxDocuments, DocumentItem,
    ContentButtons
} from "./styles"
import API from "../../../services/api"
import formatDate from "../utils"

export default function ConfirmStep({setStep, cadastro, setCadastroFinalizado}) {
    const [assunto, setAssunto] = useState();

    useEffect(() => {
        getAssunto(cadastro)
	}, [cadastro])

    function getAssunto(cadastro){
        cadastro && cadastro.assuntos.map((ass) => {
            if(ass.id == parseInt(cadastro.AssuntoId)){
                setAssunto(ass)
            }
        }) 
    }

    function saveCadastro(){
        cadastro["validar"] = false
        API.post('agendamento', cadastro).then(response => {
            setCadastroFinalizado(response.data)
            setStep(3)
        });
     
    }

    return (
        <Container>
            <BoxContainer>
                <TextInfo>Local do atendimento: <TextBold>{cadastro && cadastro.localText}</TextBold></TextInfo>
                <TextInfo>Data e horário do atendimento: <TextBold>{cadastro && formatDate(cadastro.Horario)}</TextBold></TextInfo>
                <TextInfo>Assunto: <TextBold>{assunto && assunto.descricao}</TextBold></TextInfo>
                <TextInfo>Documentos Necessários:</TextInfo>
                <BoxDocuments>
                    {
                        assunto && assunto.documentos.map((doc, index) => (
                            <DocumentItem key={index}>{doc}</DocumentItem>
                        ))
                    }
                </BoxDocuments>
            </BoxContainer>
            <ContentButtons>
                <ButtonForm style={{"flex": "0.55"}} onClick={() => saveCadastro()}>Confirmar Agendamento</ButtonForm>
                <ButtonBack href="#" style={{"flex": "0.4"}} onClick={() => setStep(1)}>Voltar</ButtonBack>
            </ContentButtons>
        </Container>
    )
}



