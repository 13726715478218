import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { COLORS } from "../../styles/colors"

export default function CalendarHeader({value, setValue}) {
    
    function currMonthName() {
        return value.format("MMMM")
    }

    function currYear() {
        return value.format("YYYY")
    }

    function prevMonth() {
        return value.clone().subtract(1, "month")
    }

    function nextMonth() {
        return value.clone().add(1, "month")
    }

    function thisWeek(){
        return value.isSame(new Date(), "month")
    }
    
    return (
        <div className="headerCalendar">
            <div className="arrow previous noselect" onClick={() => !thisWeek() && setValue(prevMonth())}>
                {   
                    !thisWeek() 
                    ? <FontAwesomeIcon color={COLORS.primary} icon={faChevronLeft} />  
                    : <FontAwesomeIcon color={COLORS.greycolor} icon={faChevronLeft} />
                }
            </div>
            <div className="current noselect">{currMonthName()}, {currYear()}</div>
            <div className="arrow next noselect" onClick={() =>  setValue(nextMonth())}><FontAwesomeIcon color={COLORS.primary} icon={faChevronRight} /></div>
        </div>
    )
}
