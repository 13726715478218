import styled from "styled-components";
import { COLORS } from "../../../styles/colors";


export const CookiesSection = styled.footer`
    position: fixed;
    bottom: 0px;
    background-color: #002350;
    color: #000;
    z-index: 5;
    margin: unset;
    align-items: center;
    width: 100%;
    display: ${props => (props.openModal ? 'block' : 'none')};

`
export const CookiesInner = styled.footer`
    display: flex;
    padding: 20px 0px;

    @media(max-width: 1000px) {
       flex-direction: column;
       align-items: center;
    }
`

export const TextCookie = styled.p`
    color: ${COLORS.white};
    font-size: 13px;
    line-height: 18px;

    a{
        text-decoration: underline;
        color: #fff

    }
`

export const ContentButtons = styled.div`
    display:flex;
    align-items: center;

    @media(max-width: 1000px) {
       margin-top: 20px;
    }
`

export const Button = styled.button`
    background-color: ${COLORS.buttonColor};
    width: fit-content;
    color: ${COLORS.white};
    font-size: 15px;
    line-height: 22px;
    cursor: pointer;
    transition: 0.4s;
    text-decoration: none;
    border:none;
    margin-right: 10px;
    padding: 5px 10px;

    &:hover{
        color: ${COLORS.buttonColor};
        background-color: ${COLORS.white}
    }

  
`