import styled from "styled-components";
import { COLORS } from "../../../styles/colors";


export const Form = styled.form`
    display:flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
`

export const TitleForm = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: ${COLORS.secondary};
    margin-bottom: 25px;
    line-height: 21px;

    @media(max-width: 400px) {
        font-size: 15px;
    }
`
export const TextNormal = styled.p`
    font-size: 18px;
    color: ${COLORS.secondary};
    line-height: 21px;

    @media(max-width: 400px) {
        font-size: 15px;
    }
`

export const RequiredBox = styled.p`
    font-size: 13px;
    color: ${COLORS.greycolor};
    line-height: 21px;
    font-weight: bold;
    background-color: ${COLORS.greyBoxColor};
    padding: 8px 10px;
    margin-bottom: 20px;
`

export const FormRow = styled.div`
    display:flex;
    width: 100%;
    margin: 10px 0px;
    justify-content: space-between;
 

    @media(max-width: 1000px) {
        margin: 0px;
        flex-direction: column;
    }
`

export const FormField = styled.div`
    width: 65%;
    display:flex;
    flex-direction: column;

    @media(max-width: 1000px) {
        width: 100%;
        margin-top: 10px;
    }
`

export const FormSmallField = styled.div`
    width: 30%;
    display:flex;
    flex-direction: column;

    @media(max-width: 1000px) {
        width: 100%;
        margin-top: 10px;
    }

`
export const FormFullField = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
`

export const FormFullCheckField = styled.div`
    width: 100%;
    display:flex;

    @media(max-width: 1000px) {
        align-items: initial;
        margin: 30px 0px;
        flex-direction: column
    }
`

export const FormLabel = styled.label`
    width: 100%;
    color: ${COLORS.labelColor};
    font-weight: bold;
    padding: 10px 0px;
`

export const FormInput = styled.input`
    width: 100%;
    border-radius: 8px;
    height: 48px;
    color: ${COLORS.secondary};
    font-size: 16px;
    padding: 0px 12px;
    border: ${props => props.requiredError 
        ? `1px solid ${COLORS.danger}` 
        : `1px solid ${COLORS.inputColor}`};

    ::placeholder {
        color: ${COLORS.inputColor};
    }
`
export const FormInputCheck = styled.input`
    width: 13%;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
    border: ${props => props.requiredError 
        ? `1px solid ${COLORS.danger}` 
        : `1px solid ${COLORS.inputColor}`};

    @media(max-width: 1000px) {
       margin-bottom: 10px;
    }
`


export const FormSelectInput = styled.select`
    width: 100%;
    border-radius: 8px;
    height: 48px;
    font-size: 16px;
    padding: 0px 12px;
    color: ${COLORS.secondary};
    border: ${props => props.requiredError 
        ? `1px solid ${COLORS.danger}` 
        : `1px solid ${COLORS.inputColor}`};

    ::placeholder {
        font-weight: bold;
        color: ${COLORS.inputColor};
    }
`

export const FormTextAreaInput = styled.textarea`
    width: 100%;
    border-radius: 8px;
    height: 100px;
    color: ${COLORS.secondary};
    font-size: 16px;
    padding: 12px 12px;
    resize: none;
    font-family: Arial;
    border: ${props => props.requiredError 
        ? `1px solid ${COLORS.danger}` 
        : `1px solid ${COLORS.inputColor}`};

    ::placeholder {
        color: ${COLORS.inputColor};
    }
`

export const LabelFor= styled.label`
    font-size: 13px;
    color: ${COLORS.secondary};
    line-height: 21px;
    cursor: pointer;
    user-select: none;
`

export const ButtonLink = styled.a`
    width:100%;
    color: ${COLORS.primary};
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
`

export const ErrorMessage = styled.span`
    font-size: 14px;
    color: ${COLORS.danger};
    line-height: 21px;
    margin-top: 5px;
`
