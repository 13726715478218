
export const COLORS = {
  primary: '#002F6B',
  secondary: '#707070',
  greycolor: '#A3A3A3',
  greyBoxColor: '#F2F2F2',
  white: '#fff',
  labelColor: '#666666',
  inputColor: '#D2D2D2',
  success: '#9DCA83',
  danger: '#E37A7A',
  buttonColor: '#002F6B',
  boxColor: '#ECECEC',
  blackColor: '#000',
  transparent: 'transparent',
  darkTransparent: 'rgba(0,0,0,0.6)',
  whiteTransparent: 'rgba(255,255,255,0.3)',
};
